import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  TextField,
  Slider,
  Typography,
  Button,
  Paper,
  Stack,
  Grid,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  IconButton,
  Switch,
} from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

type ExportFormat = 'png-with-bg' | 'png-transparent' | 'jpg' | 'svg';
type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const App: React.FC = () => {
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState(96);
  const [letterSpacing, setLetterSpacing] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('#36B2FC');
  const [backgroundWidth, setBackgroundWidth] = useState(800);
  const [backgroundHeight, setBackgroundHeight] = useState(400);
  const [tabValue, setTabValue] = useState(0);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [useGradient, setUseGradient] = useState(false);
  const [gradientColor1, setGradientColor1] = useState('#FF69B4');
  const [gradientColor2, setGradientColor2] = useState('#FFD700');
  const [gradientAngle, setGradientAngle] = useState(45);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [showGradientColor1Picker, setShowGradientColor1Picker] = useState(false);
  const [showGradientColor2Picker, setShowGradientColor2Picker] = useState(false);

  const previewRef = useRef<HTMLDivElement>(null);

  // Export menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleExport = async (format: ExportFormat) => {
    if (!previewRef.current) return;

    const fileName = `barbie-text-${Date.now()}`;
    const element = previewRef.current;
    const originalBackground = element.style.background;

    // 获取元素的实际尺寸
    const rect = element.getBoundingClientRect();
    const scale = window.devicePixelRatio || 1;
    const width = Math.floor(rect.width * scale);
    const height = Math.floor(rect.height * scale);

    const options = {
      width,
      height,
      useCORS: true,
      logging: true,
      scale, // 使用设备像素比来提高清晰度
    };

    switch (format) {
      case 'png-with-bg':
        element.style.background = backgroundColor;
        const canvasWithBg = await html2canvas(element, {
          ...options,
          background: backgroundColor,
        });
        element.style.background = originalBackground;
        canvasWithBg.toBlob((blob: Blob | null) => {
          if (blob) saveAs(blob, `${fileName}.png`);
        });
        break;

      case 'png-transparent':
        element.style.background = 'transparent';
        const canvasTransparent = await html2canvas(element, {
          ...options,
          background: 'transparent',
        });
        element.style.background = originalBackground;
        canvasTransparent.toBlob((blob: Blob | null) => {
          if (blob) saveAs(blob, `${fileName}-transparent.png`);
        });
        break;

      case 'jpg':
        element.style.background = backgroundColor;
        const canvasJpg = await html2canvas(element, {
          ...options,
          background: backgroundColor,
        });
        element.style.background = originalBackground;
        canvasJpg.toBlob((blob: Blob | null) => {
          if (blob) saveAs(blob, `${fileName}.jpg`);
        }, 'image/jpeg', 0.95);
        break;

      case 'svg':
        const svgContent = `
          <svg xmlns="http://www.w3.org/2000/svg" width="${backgroundWidth}" height="${backgroundHeight}">
            <style>
              @font-face {
                font-family: 'BarbieScript';
                src: url('./BarbieScript-gxYjP-2.otf') format('opentype');
              }
            </style>
            <rect width="100%" height="100%" fill="${backgroundColor}"/>
            <g transform="rotate(${rotation} ${backgroundWidth/2} ${backgroundHeight/2})">
              <text
                x="50%"
                y="50%"
                font-family="BarbieScript, 'Arial Rounded MT Bold', sans-serif"
                font-size="${fontSize}px"
                letter-spacing="${letterSpacing}px"
                text-anchor="middle"
                dominant-baseline="middle"
                fill="#FFFFFF"
                stroke="#FE0084"
                stroke-width="3"
              >${text || 'Barbie'}</text>
            </g>
          </svg>
        `;
        const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' });
        saveAs(svgBlob, `${fileName}.svg`);
        break;
    }

    handleExportClose();
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography 
        variant="h3" 
        align="center" 
        gutterBottom 
        sx={{ 
          color: '#FF69B4',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(255, 105, 180, 0.3)',
          animation: 'fadeIn 0.8s ease-in',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(-20px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          }
        }}
      >
        Barbie Font Generator
      </Typography>

      <Box sx={{ mb: 4, animation: 'slideIn 0.6s ease-out', '@keyframes slideIn': {
        from: { opacity: 0, transform: 'translateX(-20px)' },
        to: { opacity: 1, transform: 'translateX(0)' }
      }}}>
        <TextField
          fullWidth
          label="Enter your text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          variant="outlined"
          sx={{ 
            mb: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#FFB6C1',
                transition: 'all 0.3s ease'
              },
              '&:hover fieldset': {
                borderColor: '#FF69B4'
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FF1493'
              }
            },
            '& .MuiInputLabel-root': {
              color: '#FF69B4'
            }
          }}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root': { 
                color: '#FF69B4',
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: '#FF1493',
                  transform: 'translateY(-1px)'
                }
              },
              '& .Mui-selected': { 
                color: '#FF1493 !important',
                fontWeight: 'bold'
              },
              '& .MuiTabs-indicator': { 
                backgroundColor: '#FF1493',
                height: '3px',
                transition: 'all 0.3s ease'
              }
            }}
          >
            <Tab label="Font Settings" />
            <Tab label="Background Settings" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom>Font Size: {fontSize}px</Typography>
              <Slider
                value={fontSize}
                onChange={(_, value) => setFontSize(value as number)}
                min={24}
                max={240}
                sx={{ 
                  color: '#FF69B4',
                  '& .MuiSlider-thumb': {
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                    }
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.32
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Letter Spacing: {letterSpacing}px</Typography>
              <Slider
                value={letterSpacing}
                onChange={(_, value) => setLetterSpacing(value as number)}
                min={-5}
                max={20}
                sx={{ 
                  color: '#FF69B4',
                  '& .MuiSlider-thumb': {
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                    }
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.32
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Rotation: {rotation}°</Typography>
              <Slider
                value={rotation}
                onChange={(_, value) => setRotation(value as number)}
                min={-180}
                max={180}
                sx={{ 
                  color: '#FF69B4',
                  '& .MuiSlider-thumb': {
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                    }
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.32
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom sx={{ mb: 2 }}>Text Color</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography>Use Gradient</Typography>
                  <Switch
                    checked={useGradient}
                    onChange={(e) => setUseGradient(e.target.checked)}
                    sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#FF69B4' } }}
                  />
                </Box>
                {!useGradient ? (
                  <Box sx={{ position: 'relative' }}>
                    <Button
                      onClick={() => setShowTextColorPicker(!showTextColorPicker)}
                      variant="outlined"
                      sx={{
                        borderColor: '#FF69B4',
                        color: '#FF69B4',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 2px 4px rgba(255, 105, 180, 0.1)',
                        '&:hover': {
                          borderColor: '#FF1493',
                          transform: 'translateY(-1px)',
                          boxShadow: '0 4px 8px rgba(255, 105, 180, 0.2)'
                        },
                        '&:active': {
                          transform: 'translateY(0)'
                        },
                        '& .color-preview': {
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                          borderRadius: '4px',
                          border: '1px solid #FF69B4',
                          transition: 'all 0.3s ease'
                        }
                      }}
                    >
                      <span className="color-preview" style={{ backgroundColor: textColor }} />
                      Select Color
                    </Button>
                    {showTextColorPicker && (
                      <Box sx={{
                        position: 'absolute',
                        zIndex: 1,
                        mt: 1,
                        animation: 'fadeIn 0.2s ease-out',
                        '@keyframes fadeIn': {
                          from: { opacity: 0, transform: 'translateY(-10px)' },
                          to: { opacity: 1, transform: 'translateY(0)' }
                        }
                      }}>
                        <Box
                          sx={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                          onClick={() => setShowTextColorPicker(false)}
                        />
                        <HexColorPicker color={textColor} onChange={setTextColor} />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <Box sx={{ position: 'relative' }}>
                        <Button
                          onClick={() => setShowGradientColor1Picker(!showGradientColor1Picker)}
                          variant="outlined"
                          sx={{
                            borderColor: '#FF69B4',
                            color: '#FF69B4',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 2px 4px rgba(255, 105, 180, 0.1)',
                            '&:hover': {
                              borderColor: '#FF1493',
                              transform: 'translateY(-1px)',
                              boxShadow: '0 4px 8px rgba(255, 105, 180, 0.2)'
                            },
                            '&:active': {
                              transform: 'translateY(0)'
                            },
                            '& .color-preview': {
                              width: '20px',
                              height: '20px',
                              marginRight: '8px',
                              borderRadius: '4px',
                              border: '1px solid #FF69B4',
                              transition: 'all 0.3s ease'
                            }
                          }}
                        >
                          <span className="color-preview" style={{ backgroundColor: gradientColor1 }} />
                          Color 1
                        </Button>
                        {showGradientColor1Picker && (
                          <Box sx={{
                            position: 'absolute',
                            zIndex: 1,
                            mt: 1,
                            animation: 'fadeIn 0.2s ease-out',
                            '@keyframes fadeIn': {
                              from: { opacity: 0, transform: 'translateY(-10px)' },
                              to: { opacity: 1, transform: 'translateY(0)' }
                            }
                          }}>
                            <Box
                              sx={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                              }}
                              onClick={() => setShowGradientColor1Picker(false)}
                            />
                            <HexColorPicker color={gradientColor1} onChange={setGradientColor1} />
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ position: 'relative' }}>
                        <Button
                          onClick={() => setShowGradientColor2Picker(!showGradientColor2Picker)}
                          variant="outlined"
                          sx={{
                            borderColor: '#FF69B4',
                            color: '#FF69B4',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 2px 4px rgba(255, 105, 180, 0.1)',
                            '&:hover': {
                              borderColor: '#FF1493',
                              transform: 'translateY(-1px)',
                              boxShadow: '0 4px 8px rgba(255, 105, 180, 0.2)'
                            },
                            '&:active': {
                              transform: 'translateY(0)'
                            },
                            '& .color-preview': {
                              width: '20px',
                              height: '20px',
                              marginRight: '8px',
                              borderRadius: '4px',
                              border: '1px solid #FF69B4',
                              transition: 'all 0.3s ease'
                            }
                          }}
                        >
                          <span className="color-preview" style={{ backgroundColor: gradientColor2 }} />
                          Color 2
                        </Button>
                        {showGradientColor2Picker && (
                          <Box sx={{
                            position: 'absolute',
                            zIndex: 1,
                            mt: 1,
                            animation: 'fadeIn 0.2s ease-out',
                            '@keyframes fadeIn': {
                              from: { opacity: 0, transform: 'translateY(-10px)' },
                              to: { opacity: 1, transform: 'translateY(0)' }
                            }
                          }}>
                            <Box
                              sx={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                              }}
                              onClick={() => setShowGradientColor2Picker(false)}
                            />
                            <HexColorPicker color={gradientColor2} onChange={setGradientColor2} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Typography gutterBottom>Gradient Angle: {gradientAngle}°</Typography>
                    <Slider
                      value={gradientAngle}
                      onChange={(_, value) => setGradientAngle(value as number)}
                      min={0}
                      max={360}
                      sx={{ 
                        color: '#FF69B4',
                        '& .MuiSlider-thumb': {
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                          }
                        },
                        '& .MuiSlider-rail': {
                          opacity: 0.32
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <label>Background Color</label>
            <HexColorPicker color={backgroundColor} onChange={setBackgroundColor} />
            <Typography gutterBottom>Background Width: {backgroundWidth}px</Typography>
            <Slider
              value={backgroundWidth}
              onChange={(_, value) => setBackgroundWidth(value as number)}
              min={400}
              max={2000}
              step={50}
              sx={{ 
                color: '#FF69B4',
                '& .MuiSlider-thumb': {
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                  }
                },
                '& .MuiSlider-rail': {
                  opacity: 0.32
                }
              }}
            />
            <Typography gutterBottom>Background Height: {backgroundHeight}px</Typography>
            <Slider
              value={backgroundHeight}
              onChange={(_, value) => setBackgroundHeight(value as number)}
              min={200}
              max={1000}
              step={50}
              sx={{ 
                color: '#FF69B4',
                '& .MuiSlider-thumb': {
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(255, 105, 180, 0.16)'
                  }
                },
                '& .MuiSlider-rail': {
                  opacity: 0.32
                }
              }}
            />
          </Box>
        </TabPanel>
      </Box>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          mb: 4,
          minHeight: 200,
          width: backgroundWidth,
          height: backgroundHeight,
          maxWidth: '100%',
          mx: 'auto',
          overflow: 'auto',
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          background: backgroundColor,
          transition: 'all 0.3s ease',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            boxShadow: '0 12px 24px rgba(255, 105, 180, 0.2)'
          }
        }}
        id="preview-container"
      >
        <Box 
          ref={previewRef} 
          sx={{
            position: 'relative',
            display: 'inline-block',
            padding: '10px',
            margin: '10px',
            cursor: 'default',
            transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
            transform: `rotate(${rotation}deg)`,
            '&:hover': {
              transform: `scale(1.05) rotate(${rotation}deg)`,
              filter: 'drop-shadow(0 0 10px rgba(255, 105, 180, 0.3))'
            }
          }}
        >
          {/* 粉色描边层 */}
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              left: 20,
              fontFamily: "'BarbieScript', 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif",
              fontWeight: "bold",
              fontSize: `${fontSize}px`,
              color: "#FE0084",
              textTransform: "none",
              letterSpacing: `${letterSpacing}px`,
              textShadow: `
                -3px -3px 0 #FE0084,
                3px -3px 0 #FE0084,
                -3px 3px 0 #FE0084,
                3px 3px 0 #FE0084,
                6px 6px 0 #FE0084
              `,
            }}
          >
            {text || 'Barbie'}
          </Box>
          {/* 白色文字层 */}
          <Box
            sx={{
              position: 'relative',
              fontFamily: "'BarbieScript', 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif",
              fontWeight: "bold",
              fontSize: `${fontSize}px`,
              color: textColor,
              textTransform: "none",
              letterSpacing: `${letterSpacing}px`,
              background: useGradient
                ? `linear-gradient(${gradientAngle}deg, ${gradientColor1}, ${gradientColor2})`
                : 'none',
              WebkitBackgroundClip: useGradient ? 'text' : 'none',
              WebkitTextFillColor: useGradient ? 'transparent' : textColor,
            }}
          >
            {text || 'Barbie'}
          </Box>
        </Box>
      </Paper>

      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="contained"
          onClick={handleExportClick}
          sx={{
            bgcolor: '#FF69B4',
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 6px rgba(255, 105, 180, 0.2)',
            '&:hover': {
              bgcolor: '#FF1493',
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 12px rgba(255, 105, 180, 0.3)'
            },
            '&:active': {
              transform: 'translateY(0)'
            }
          }}
        >
          Export
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleExportClose}
        >
          <MenuItem onClick={() => handleExport('png-with-bg')}>
            PNG with Background
          </MenuItem>
          <MenuItem onClick={() => handleExport('png-transparent')}>
            PNG (Transparent)
          </MenuItem>
          <MenuItem onClick={() => handleExport('jpg')}>
            JPG
          </MenuItem>
          <MenuItem onClick={() => handleExport('svg')}>
            SVG
          </MenuItem>
        </Menu>
      </Stack>
    </Container>
  );
};
